
// vars
.text-djgreen{
    color: $djgreen !important;
}
.bg-djgreen{
    background: $djgreen !important;
}

.text-bwgreen{
    color: $bwgreen;
}
.bg-bwgreen{
    background: $bwgreen !important;
}

.text-bgreen{
    color: $bgreen;
}
.bg-bgreen{
    background: $bgreen !important;
}

.text-emerald{
    color: $bgreen;
}

.bg-emerald{
    background: $bgreen !important;
}

.text-ogreen1{
    color: $ogreen1;
}
.bg-ogreen1{
    background: $bgreen !important;
}

.text-ogreen2{
    color: $ogreen2;
}
.bg-ogreen2{
    background: $ogreen2 !important;
}


.text-tgreen1{
    color: $ogreen1;
}
.bg-tgreen1{
    background: $ogreen1 !important;
}

.text-tgreen2{
    color: $ogreen2;
}
.bg-tgreen2{
    background: $ogreen2 !important;
}

.text-nyanza{
    color: $nyanza;
}

.bg-nyanza{
    background: $nyanza !important;
}

.text-green1{
    color: $green1;
}
.bg-green1{
    background: $bgreen !important;
}

.text-red1{
    color: $red1;
}
.bg-red1{
    background: $red1;
}

.text-brown1{
    color: #32302e;
}
.bg-brown1{
    background: $brown1;
}

.text-purple1{
    color: $purple1;
}
.bg-purple1{
    background: $purple2;
}

.text-bronze{
    color: $bronze;
}
.bg-bronze{
    background: $bronze;
}

// other colors
.text-color-1{
    color: #57b6bb;
}

.text-color-2{
    color: #4FB047;
}
.text-yellow {
    color: #ffc107 !important;
  }

.text-red {
    color: #DC0000 !important;
  }

.bg-yellow {
    color: #ffc107 !important;
  }

.bg-red {
    color: #DC0000 !important;
  }

.text-green{
    color: #4FB047;
}

// -----------


// BACKGROUND
.background-1{


    background-color: #4FB047;
   // background-image: linear-gradient(0deg, #448e5a 0%, #aecb6e 100%);

}

.background-2{
    background-color: #1F2F46;
    background-image: radial-gradient( circle 590px at 8.2% 13.8%,  rgba(18,35,60,1) 0%, #bbb 90% );
}
.background-3{
    background-color: #4cbd6d;
    background-image: linear-gradient(90deg, #4cbd6d 0%, #d1f97d 100%);
}
.background-4{
    background-color: #90F7EC;
    background-image: linear-gradient( 135deg, #90F7EC 10%, #32CCBC 100%);
}

.background-5{
    background-color: #74c69d;
    background-image: linear-gradient(90deg, #74c69d 0%, #52b788 50%, #40916c 100%);
}


.background-6{
    background-image: linear-gradient( 110.3deg,  rgb(36, 41, 51) 4.3%, rgb(43, 44, 49) 96.7% );
}
