
.customer{
    .pageTitle {
        font-size: 17px;
        font-weight: 500;
        padding: 0px 10px;
        .logo {
            max-height: 34px;
        }
        img, svg {
            vertical-align: middle;
        }
    }

    .profileBox {
        padding: 0 16px;
        display: flex;
        align-items: center;

        .image-wrapper {
            margin-right: 16px;

        }

        .imaged.w36 {
            width: 36px !important;
        }
        .imaged {
            height: auto;
            border-radius: 10px;
        }

    }

    .sidebar-balance {
        padding: 6px 16px;
        // background: $emerald;

        .listview-title {
            padding-right: 0;
            padding-left: 0;
            color: #FFF;
            opacity: .6;
        }

        .amount {
            font-weight: 700;
            letter-spacing: -0.01em;
            line-height: 1em;
            font-weight: 32px;
            color: #FFF;
            margin-bottom: 6px;
        }
    }


    .action-group {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0px 16px 10px 16px;
        // background: $emerald;

        .action-button {
            padding: 10px 2px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 11px;
            line-height: 1em;
            color: rgba(255,255,255,0.7);
        }
        .iconbox {
            background: rgba(0,0,0,0.3);
            width: 38px;
            height: 38px;
            margin: 0 auto 8px auto;
            border-radius: 100%;
            font-size: 18px;
            color: #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }


    .total {
        font-weight: 700;
        letter-spacing: -0.01em;
        line-height: 1em;
        font-size: 32px;
    }



    .wallet-card {
        background: #ffffff;
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
        border-radius: 10px;
        padding: 20px 24px;
        position: relative;
        z-index: 1;

        .balance {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            height: 100px;

            .left {
                padding-right: 10px;
            }


        }

        .title {
            color: #27173E;
            font-weight: 500;
            display: block;
            margin-bottom: 8px;
        }

    }




    .settings{
        padding: 10px 50px;

        .card{
        border-radius: 10px !important;

        }
        .title{
            font-weight: bold;
        }
        .in{
            display: flex;
            justify-content: space-between;
        }
    }
    @media (max-width: 575.98px) {
        .settings{

            padding: 0px;
        /* border-radius: 10px; */
        }

        .total{
            font-size: 16px;

        }


      }

    @media (max-width: 767.98px) {
        .settings{

            padding: 0px;
        /* border-radius: 10px; */
        }

        .total{
            font-size: 20px;

        }

        .wallet-card .title{
            font-size: 13px !important;

        }

    }


}




.general{

    .add-cash{

        font-size: 2.4rem;
        border-radius: 10px;
    }


    .add-cash:hover{
        background-color: #CCD673;
        background-image: linear-gradient( 135deg, #CCD673 10%, #a3d673 100%);
    }

    .icon-wrapper {
        background: #6236FF;
        width: 48px;
        height: 48px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        margin-bottom: 14px;
    }
     .wallet-footer {
        border-top: 1px solid #DCDCE9;
        padding-top: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        strong {
            display: block;
            color: #27173E;
            font-weight: 500;
            // font-size: 1rem;
            line-height: 1.2em;
        }
    }

    legend{
        font-size: 0.9rem;

        span{
            font-size: 1rem;
        }
    }





    @media (max-width: 575.98px) {
        .add-cash{

        font-size: 1.4rem;
        /* border-radius: 10px; */
        }
        .icon-wrapper {
        width: 30px;
        height: 30px;

        font-size: 16px;
        margin-bottom: 10px;
        }
        .wallet-footer strong {
            font-size: 0.5rem;

        }




      }

    @media (max-width: 767.98px) {
        .add-cash{

        font-size: 1.4rem;
        /* border-radius: 10px; */
    }
    .icon-wrapper {
        width: 38px;
        height: 38px;

        font-size: 20px;
        margin-bottom: 10px;
    }
        .wallet-footer strong {
            font-size: 0.6rem;

        }
    }

    @media (max-width: 991.98px) {  }

    @media (max-width: 1199.98pxs) { }


}



.transactions{
    .imaged.w48 {
        width: 48px !important;
    }

    section{
        background: #ffffff;
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
        border-radius: 10px;
        // cursor: pointer;
    }

    .item {
        // background: #ffffff;
        // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
        border-radius: 10px;
        padding: 20px 24px;
        margin-bottom: 10px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .detail {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            line-height: 1.2em;

            .image-block {
                margin-right: 16px;
            }

            strong {
                display: block;
                font-weight: 500;
                color: #27173E;
                margin-bottom: 3px;

                &:hover{
                    color: $emerald;
                }
            }
        }
        .right {
            padding-left: 10px;

            .price {
                font-weight: 700;
                // color: #27173e;
                letter-spacing: -0.03em;
              }
          }

        p {
            font-size: 11px;
            margin: 0;
            color: #958d9e;
            font-weight: 500;
        }

    }
    @media (max-width: 575.98px) {


        .item{
            padding: 20px 0;
        }

      }

    @media (min-width: 768px) {
        .imaged.w48 {
            width: 96px !important;
        }

        .item{
            strong{
                font-size: 1.15rem;
            }
            p{
                font-size: 0.9rem;
            }
            .right {
                .price{
                    font-size: 1.25rem ;
                }
            }
        }


    }

//     @media (max-width: 575.98px) {
//     .item{
//         flex-direction: column-reverse;
//     }

//     .text-lg{
//         font-size: 10px !important;
//     }

//     .custom-button-2, .custom-button{
//         font-size: 13px !important;
//         // padding: 2.8rem 3.8rem;
//         padding: 0.2rem 0.5rem;
//     }
//     strong {
//         font-size: 13px !important;

//     }

//     }

//   @media (max-width: 767.98px) {
//         .text-lg{
//             font-size: 13px !important;
//         }

//     }

}


// loaders


.loader-1,
.loader-1:before,
.loader-1:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader-1 {
  /* color: #ffffff; */
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader-1:before,
.loader-1:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader-1:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader-1:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}


