// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #4FB047;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$bronze: #C67F3F;


// website variables

$djgreen: #081c15;
$bwgreen: #1b4332;
$bgreen: #4FB047;
$emerald: #40916c;
$ogreen1: #52b788;
$ogreen2: #74c69d;
$tgreen1: #95d5b2;
$tgreen2: #b7e4c7;
$nyanza : #d8f3dc;
$green1: #CCD673;
$red1: #DF5845;
$red2: #ff8490;
$brown1: #6E615A;
$purple1: #bd95d4;
$purple2: #CDB4DB;
$kyellow: #ffed4a;



