// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Admin LTE
@import "~admin-lte/dist/css/adminlte.css";

// Variables
@import "variables";

@import "variable-classes";

// Custom Styles
@import "styles2";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

/* width */
.background-1 {
    ::-webkit-scrollbar {
        width: 8px;
        height: 3px;
    }

    //   ::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //   }

    //   ::-webkit-scrollbar-thumb {
    //     background-color: $bgreen;
    //     outline: 1px solid $emerald;
    //   }
}

.background-6 {
    ::-webkit-scrollbar {
        width: 8px;
        height: 3px;
    }

    //   ::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //     border-radius: 100px;
    //   }

    //   ::-webkit-scrollbar-thumb {
    //     background-image: linear-gradient(
    //         110.3deg, #20412d 4.3%, #011604 96.7%);
    //     outline: 1px solid #2b2c31;
    //     border-radius: 100px;
    //   }
}

// cards
.custom-card-1 {
    background: #fff;
    border-radius: 2rem;
    box-shadow: 0 0 3.4rem 0 rgb(0 0 0 / 10%);
    margin: 5rem auto 10rem;
    padding: 1rem;
}

// buttons

.btn-grad-1 {
    background-image: linear-gradient(
        to right,
        #3ca55c 0%,
        #b5ac49 51%,
        #3ca55c 100%
    );
}
.btn-grad-1 {
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 5rem;
    display: block;
}

.btn-grad-1:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.custom-button-1 {
    border: 1px solid $bgreen;
    border-radius: 5rem;
    color: $bgreen !important;
    width: 100px;

    &:hover {
        background: $bgreen;
        color: white !important;
    }
}

.custom-button-2 {
    border: none !important;
    border-radius: 5rem;
    color: white !important;
    background: $bgreen;
    font-weight: bold;

    &:hover {
        background: $kyellow;
        border: none !important;
        color: $green !important;
    }
}

.custom-button-purple1 {
    border: 1px solid $purple1;
    border-radius: 5rem;
    color: white !important;
    background: $purple1;
    font-weight: bold;

    &:hover {
        border: 1px solid $purple2;

        background: $purple2;
    }
}

.custom-button-red1 {
    border: 1px solid $red1;
    border-radius: 5rem;
    color: white !important;
    background: $red1;
    font-weight: bold;

    &:hover {
        border: 1px solid $red2;

        background: $red2;
    }
}
.custom-button-ogreen1 {
    border: 1px solid $ogreen1;
    border-radius: 5rem;
    color: white !important;
    background: $ogreen1;
    font-weight: bold;

    &:hover {
        border: 1px solid $ogreen2;

        background: $ogreen2;
    }
}

.custom-button {
    border: 1px solid inherit;
    border-radius: 5rem;
    // color: white !important;
    // background: $red;
    font-weight: bold;
}

.btn-outline-bgreen {
    color: $bgreen;
    border-color: $bgreen;

    &:hover {
        border-color: $bgreen;
        background: $bgreen;
        color: white;
    }
}

.navbar a {
    font-weight: bold !important;
}

.auth a {
    color: $bgreen;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
        color: $djgreen;
    }
}

//
a {
    color: inherit;

    // &:hover{
    //     color: $bgreen;
    // }

    // &:active{
    //     color: $bwgreen;
    // }
}

//
.sidebar .nav-link {
    color: white !important;
}


.dashboard1 .nav-pills .nav-link.active,
.dashboard1 .nav-pills .show > .nav-link {
    border: none !important;
    color: rgb(72, 72, 84);
    margin-right: 0;
    background-color: #fcfcfc;
    background-image: linear-gradient(141deg, #f8e099 0%, #FFC107 100%)!important;
    border-radius: 0px 25px 25px 0px;
    transition: margin-right 0.2s;
    transition-timing-function: ease-in;
}
.dashboard1 .nav-pills .nav-link.active,
.dashboard1 .nav-pills .show > .nav-link:active {
    margin-right: 3rem;
}
.dashboard1 .nav-pills .nav-link.active,
.dashboard1 .nav-pills .show > .nav-link:active p {
    color: $djgreen !important;
}

.dashboard1 .nav-pills .nav-link.active,
.dashboard1 .nav-pills .show > .nav-link:hover {
    color: $djgreen !important;
    cursor: pointer;
}

// broker
.broker .nav-pills .nav-link.active,
.broker .nav-pills .show > .nav-link {
    color: rgb(72, 72, 84);
    background-color: #fcfcfc;
    background-image: linear-gradient(
        110.3deg,
        rgb(79 175 71) 4.3%, rgb(14 157 2) 96.7%
    )!important;

    margin-right: 0;

    border: 2px solid inherit;
    border-radius: 0px 25px 25px 0px;
    transition: margin-right 0.2s;
    transition-timing-function: ease-in;
}
.broker .nav-pills .nav-link.active,
.broker .nav-pills .show > .nav-link:active {
    margin-right: 3rem;
}
.broker .nav-pills .nav-link.active,
.broker .nav-pills .show > .nav-link:active p {
    color: $djgreen !important;
}

.broker .nav-pills .nav-link.active,
.broker .nav-pills .show > .nav-link:hover {
    color: $nyanza !important;
    cursor: pointer;
}

// pagination buttons
.page-item.active .page-link {
    background: $emerald !important;
    border-color: $emerald !important;
}
.page-link {
    color: $emerald;
}

//
.sorting-arrow {
    color: gray;
}

.loading {
    cursor: wait !important;
}

.image-container {
    height: 350px;
    width: 100%;
    position: relative;

    .image-wrapper {
        position: relative;
        height: 300px;
        width: 100%;
        border-radius: 10px;
        background: #fff;
        border: 2px dashed #c2cdda;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .modal-image {
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        .icon {
            font-size: 100px;
            color: #9658fe;
        }
        .text {
            font-size: 20px;
            font-weight: 500;
            color: #5b5b7b;
        }
        #cancel-btn i {
            position: absolute;
            font-size: 20px;
            right: 15px;
            top: 15px;
            color: #9658fe;
            cursor: pointer;
            display: none;
        }
    }
}

// .font-size-1{
//     font-size: 1.2rem;
// }
// .font-size-2{
//     font-size: 1.4rem;

// }.font-size-3{
//     font-size: 2rem;
// }
// .font-size-4{
//     font-size: 2.4rem;
// }
// media queries

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .font-size-1 {
        font-size: 0.1rem;
    }
    .font-size-2 {
        font-size: 0.2rem;
    }
    .font-size-3 {
        font-size: 0.3rem;
    }
    .font-size-4 {
        font-size: 0.4rem;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
    .font-size-1 {
        font-size: 1.1rem;
    }
    .font-size-2 {
        font-size: 1.3rem;
    }
    .font-size-3 {
        font-size: 1.9rem;
    }
    .font-size-4 {
        font-size: 2.3rem;
    }
    .custom-card-1 {
        background: #fff;
        padding: 1.5rem 2rem;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .font-size-1 {
        font-size: 1.2rem;
    }
    .font-size-2 {
        font-size: 1.4rem;
    }
    .font-size-3 {
        font-size: 2rem;
    }
    .font-size-4 {
        font-size: 2.4rem;
    }
    .custom-card-1 {
        background: #fff;
        padding: 3rem 4rem;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
